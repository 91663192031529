
.analysis-answer-content {
  padding: 30px 30px 0 30px;
  height: calc(100% - 30px);
  /deep/ .pw-table-header {
    background-color: #f3f4fa;
    color: #14141c;
    font-size: 14px;
  }
  .pw-store-banner {
    height: 80px;
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    .ppt-item {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      a.file-download {
        color: #333;
        &:hover {
          color: #666;
          span {
            text-decoration: underline;
          }
        }
      }
      i.iconfont {
        font-size: 24px;
        color: #c84724;
      }
      span {
        padding-left: 5px;
        font-size: 16px;
      }
    }
  }
  .pf-act-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .pw-input {
      width: 100px;
      margin-right: 8px;
    }
    .pw-confirm-btn {
      background-color: #1222d8;
      border-color: #1222d8;
    }
  }
}
